import React from 'react';
import * as PropTypes from 'prop-types';
import { lazyload } from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ItemList } from '../../components/item-list';
import { PurchaseButton, purchaseButtonType } from '../../components/purchase-button';
import { getCategoryLink } from '../../utils/category-utils';
import {
	getItemsInCategory,
	createFollowGroupMembership,
	clearPurchaseItem,
	purchaseItem,
	getCategories,
} from '../../actions';
import logger from '../../utils/logger';
import { IAuthState } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { IPurchaseState } from '../../reducers/purchase-reducers';
import { IReduxProps } from '../../actions/action-types';
import { ICategory } from '../../reducers/category-reducers';
import { KidsModeDisclaimer } from '../../components/kids-mode-disclaimer';

const imageHeight = 180;

export interface ICategoryProps extends IReduxProps {
	category: ICategory;
	auth: IAuthState;
	subscription: ISubscriptionState;
	purchase: IPurchaseState;
	kidsMode: IKidsModeState;
}

@lazyload({
	height: imageHeight + 19,
	once: true,
	offset: 100,
	resize: true,
})
export class Category extends React.Component<ICategoryProps> {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	componentWillUnmount() {
		const { purchase, dispatch } = this.props;
		const hasPurchaseError = purchase && !purchase.isProcessing && !purchase.purchaseSuccess && purchase.error;
		const hasPurchaseSuccess = purchase && !purchase.isProcessing && !purchase.error && purchase.purchaseSuccess;
		if (hasPurchaseError || hasPurchaseSuccess) {
			dispatch(clearPurchaseItem());
		}
	}

	_renderItem(item, cover, coverEl) {
		return (
			<Link to={'/items/' + item.id} title={item.title}>
				{coverEl}
			</Link>
		);
	}

	_renderCategory(category, cover, coverEl) {
		return (
			<Link to={getCategoryLink(category)} title={category.title}>
				{coverEl}
			</Link>
		);
	}

	_handlePurchase(storefront, skuId, productToken) {
		const { dispatch } = this.props;
		dispatch(purchaseItem({ storefront, skuId, productToken })).then(response => {
			if (response.result.purchaseSuccess) {
				this._followGroup();
				dispatch(getCategories(true)).catch(logger.error);
			}
		});
	}

	_getNextPage() {
		const {
			category: { nextPage, id },
			dispatch,
			kidsMode: { isKidsMode },
		} = this.props;
		dispatch(getItemsInCategory(id, nextPage, isKidsMode));
	}

	_followGroup() {
		const {
			dispatch,
			category,
			auth: { isProcessing, user },
		} = this.props;
		const groupId = category.id.substr('group:'.length);
		const isAuthenticated = !isProcessing && user && user.id !== '-1';
		if (isAuthenticated) {
			dispatch(createFollowGroupMembership(groupId))
				.then(() => {
					const groupRedirect = category && category.token ? `/groups/${category.token}` : '/';
					this.context.router.history.push(groupRedirect);
				})
				.catch(logger.error);
		}
	}

	render() {
		const { category, kidsMode, subscription } = this.props;
		const isKidsMode = kidsMode && !!kidsMode.isKidsMode;
		const items = category.items || [];
		const subcategories = category.subcategories || [];
		const purchaseInfo =
			category && category.productInfo && (category.productInfo.quickBuy || category.productInfo.addToCart);

		if (!items.length && !subcategories.length) {
			return false;
		}
		const categoryUrl = getCategoryLink(category);

		return (
			<section className="category">
				<div className={`title-area${isKidsMode ? ' title-area-kids' : ''}`}>
					<Link className="title-link" to={categoryUrl}>
						<h4 className={`title${purchaseInfo ? ' title-inline' : ''}`}>{category.title}</h4>
					</Link>
					{purchaseInfo && purchaseInfo.skuId ? (
						isKidsMode ? (
							<KidsModeDisclaimer />
						) : (
							<PurchaseButton
								purchase={{
									price: purchaseInfo.displayAmount,
									subscriptionPeriodKind: purchaseInfo.subscriptionPeriodKind,
								}}
								onPurchase={() =>
									this._handlePurchase(purchaseInfo.storefront, purchaseInfo.skuId, purchaseInfo.productToken)
								}
								type={purchaseButtonType.Compact}
							/>
						)
					) : purchaseInfo ? (
						<a href={purchaseInfo.uri}>
							<button onClick={() => this._followGroup()} className="subscribe-button">
								Subscribe
							</button>
						</a>
					) : (
						false
					)}
				</div>
				<div className="slick-container">
					<ItemList
						items={items}
						categories={subcategories}
						pageToken={category.nextPage || null}
						imageHeight={imageHeight}
						renderItem={(item, cover, coverEl) => this._renderItem(item, cover, coverEl)}
						renderCategory={(category, cover, coverEl) => this._renderCategory(category, cover, coverEl)}
						getNextPage={() => this._getNextPage()}
						subscription={subscription}
						kidsMode={kidsMode}
					/>
				</div>
			</section>
		);
	}
}
