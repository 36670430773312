import React from 'react';

export const AddIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
		<path
			fill="#62BB46"
			fillRule="evenodd"
			d="M22.6666667,12 C22.6666667,17.8912 17.8912,22.6666667 12,22.6666667 C6.1088,22.6666667 1.33333333,17.8912 1.33333333,12 C1.33333333,6.1088 6.1088,1.33333333 12,1.33333333 C17.8912,1.33333333 22.6666667,6.1088 22.6666667,12 Z M20.5333333,12 C20.5333333,7.28717013 16.7128299,3.46666667 12,3.46666667 C7.28717013,3.46666667 3.46666667,7.28717013 3.46666667,12 C3.46666667,16.7128299 7.28717013,20.5333333 12,20.5333333 C16.7128299,20.5333333 20.5333333,16.7128299 20.5333333,12 Z M13.2,6.66666667 L13.2,10.8 L17.3333333,10.8 L17.3333333,13.2 L13.2,13.2 L13.2,17.3333333 L10.8,17.3333333 L10.8,13.2 L6.66666667,13.2 L6.66666667,10.8 L10.8,10.8 L10.8,6.66666667 L13.2,6.66666667 Z"
			transform="translate(-1 -1)"
		/>
	</svg>
);
