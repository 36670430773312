import React from 'react';
import { StarRating } from '../star-rating';
import { IRating } from '../../reducers/item-reducers';
import { IActionDispatch } from '../../actions/action-types';
import { rateItem } from '../../actions';
import { amplitudeLogEvent } from '../../helpers/amplitude-helper';

interface IItemRatingProps {
	isAuthenticated: boolean;
	canRate: boolean;
	rating?: IRating;
	itemId?: string;
	itemTitle?: string;
	dispatch?: IActionDispatch;
	onItemRated?: (rating: number) => void;
	disableRatingText?: boolean;
	location?: URL;
	hideInitialRating?: boolean;
}

export const ItemRating: React.FC<IItemRatingProps> = ({
	isAuthenticated,
	canRate,
	rating,
	itemId,
	itemTitle,
	dispatch,
	onItemRated,
	disableRatingText,
	location,
	hideInitialRating,
}) => {
	const { averageRating, ratingCount, userRating } = rating
		? {
				averageRating: rating.average || undefined,
				ratingCount: rating.ratingCount ? Math.round(rating.ratingCount) : 0,
				userRating: rating.userRating || undefined,
		  }
		: { averageRating: undefined, ratingCount: 0, userRating: undefined };

	const handleOnRated = (rating: number) => {
		if (dispatch && itemId) {
			if (onItemRated) {
				onItemRated(rating);
			}
			dispatch(rateItem({ itemId, rating }));
			amplitudeLogEvent('Item - Rated', {
				'Item Id': itemId,
				Title: itemTitle || 'Not Set',
				Rating: rating,
			});
		}
	};

	return (
		<StarRating
			location={location}
			isAuthenticated={isAuthenticated}
			canRate={canRate}
			onRated={handleOnRated}
			averageRating={averageRating}
			ratingCount={ratingCount}
			userRating={userRating}
			disableRatingText={disableRatingText}
			hideInitialRating={hideInitialRating}
		/>
	);
};
