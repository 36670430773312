import React from 'react';
import { Link } from 'react-router-dom';
import { plusDefault } from '../../data/products';
import '../home-banner/index.less';

export class SubscribeBanner extends React.Component {
	render() {
		return (
			<div className="subscribe-banner">
				<div className="header">
					<div className="wrapper">
						<h1>Watch the Bible Come Alive with Faithlife TV Plus</h1>
						<h3>Premier video library for everyone—students, kids, families, and Bible enthusiasts.</h3>
						<a href={plusDefault.url}>
							<button>Start your free trial</button>
						</a>
					</div>
				</div>
				<div className="info">
					<Link to="/get-free-trial">Learn More</Link> about Faithlife TV.
				</div>
			</div>
		);
	}
}
