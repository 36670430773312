import React from 'react';
import { Link } from 'react-router-dom';
import './index.less';
import { IRating } from '../../reducers/item-reducers';

interface IStarRatingProps extends IRating {
	isAuthenticated: boolean;
	canRate: boolean;
	onRated: (number) => void;
	averageRating?: number;
	disableRatingText?: boolean;
	location?: URL;
	hideInitialRating?: boolean;
}

interface IStarRatingState {
	userRating?: number;
	hoverIndex?: number;
}

export class StarRating extends React.Component<IStarRatingProps, IStarRatingState> {
	state: IStarRatingState = {};

	getStarHandlers(index: number) {
		if (!this.props.canRate) {
			return {};
		}
		return {
			onClick: () => this.handleOnClick(index),
			onMouseEnter: () => this.handleMouseEnter(index),
			onMouseLeave: () => this.handleMouseLeave(),
		};
	}

	_redirectUrl = () => (this.props.location && this.props.location.pathname) || '/';

	handleOnClick(index: number) {
		this.setState({ userRating: index });
		this.props.onRated(index);
	}

	handleMouseEnter(index: number) {
		this.setState({ hoverIndex: index });
	}

	handleMouseLeave() {
		this.setState({ hoverIndex: 0 });
	}

	render() {
		const {
			averageRating,
			ratingCount,
			userRating,
			isAuthenticated,
			disableRatingText,
			hideInitialRating,
		} = this.props;
		const userActiveStars = this.state.hoverIndex || this.state.userRating || userRating;
		const activeStars = hideInitialRating && !userActiveStars ? 0 : Math.round(userActiveStars || averageRating || 0);

		const stars: JSX.Element[] = [];
		for (let index = 1; index <= 5; index++) {
			const className =
				activeStars >= index ? (userActiveStars ? 'star-active-user' : 'star-active-average') : 'star-inactive';
			stars.push(
				<i key={`star-${index}`} className={className} {...this.getStarHandlers(index)}>
					<RatingStarIcon />
				</i>
			);
		}

		const titleTextLines: string[] = [];
		let totalRatings;
		let ratingText;
		if (!disableRatingText) {
			totalRatings = ratingCount === 0 && userRating ? 1 : ratingCount;
			totalRatings = this.state.userRating && !userRating ? totalRatings + 1 : totalRatings;
			ratingText =
				totalRatings >= 3 ? `${totalRatings} ratings` : this.state.userRating || userRating ? '' : 'not enough ratings';
			if (totalRatings >= 3) {
				titleTextLines.push(`Community rating: ${averageRating} stars`);
			}
		}

		if (userRating) {
			titleTextLines.push(`Your rating: ${userRating} stars`);
		}
		const titleText = titleTextLines.length ? titleTextLines.join('\n') : undefined;

		return (
			<React.Fragment>
				<div className="star-rating" title={titleText}>
					{stars}
					{ratingText && <div className="rating-text">{ratingText}</div>}
				</div>
				{!isAuthenticated && <Link to={`/signin?redirectUrl=${this._redirectUrl()}`}>Sign in to rate</Link>}
			</React.Fragment>
		);
	}
}

const RatingStarIcon = () => (
	<svg width="20" height="18" viewBox="0 0 43 41" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M22.547.94l5.788 11.729 12.943 1.881c1.044.152 1.461 1.435.706 2.171l-9.365 9.129 2.21 12.89c.178 1.04-.913 1.833-1.846 1.342l-11.577-6.086-11.577 6.086c-.933.491-2.025-.302-1.846-1.341l2.21-12.891L.83 16.72c-.756-.736-.339-2.02.704-2.171l12.943-1.881L20.264.941c.468-.946 1.816-.946 2.283 0"
			fill="#FFF"
			fillRule="evenodd"
		/>
	</svg>
);
