import React, { useState, useEffect, useRef } from 'react';
import ReactAutolink from 'react-autolink';
import { Link } from 'react-router-dom';
import { WatchlistButton } from '../../components/watchlist-button';
import { ItemRating } from '../item-rating';
import { IActionDispatch } from '../../actions/action-types';
import { IFeaturedItem } from '../../reducers/featured-items-reducers';
import './index.less';

interface IFeaturedItemsCarouselProps {
	dispatch: IActionDispatch;
	featuredItems: IFeaturedItem[];
	watchlistItemIds?: readonly string[] | null;
	isPlusSubscriber?: boolean;
}

export const FeaturedItemsCarousel: React.FC<IFeaturedItemsCarouselProps> = ({
	dispatch,
	featuredItems,
	watchlistItemIds,
	isPlusSubscriber,
}) => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const [imageLoaded, setImageLoaded] = useState<boolean>(false);
	const [featuredItem, setFeaturedItem] = useState<IFeaturedItem>(featuredItems[0]);

	useEffect(() => {
		const imageElement = imageRef.current;
		if (!imageElement) {
			return;
		}

		if (imageElement.complete) {
			setImageLoaded(true);
		} else {
			const handleImageLoaded = () => setImageLoaded(true);
			imageElement.addEventListener('load', handleImageLoaded);

			if (imageLoaded) {
				return imageElement.removeEventListener('load', handleImageLoaded);
			}
		}
	}, [imageRef, imageLoaded]);

	const handleChangeFeaturedItem = (featuredItem: IFeaturedItem) => {
		setImageLoaded(false);
		setFeaturedItem(featuredItem);
	};

	const trailer =
		featuredItem && featuredItem.trailers && featuredItem.trailers.length ? featuredItem.trailers[0] : null;

	const handleOnItemRated = rating => {
		if (featuredItem.rating && featuredItem.rating.userRating) {
			featuredItem.rating.userRating = rating;
		}
	};

	return (
		<div className="carousel-wrapper">
			<div className="gradient" />
			<img
				className={`hero-image ${imageLoaded ? 'image-loaded' : ''}`}
				src={featuredItem.heroImageUrl}
				alt={featuredItem.title}
				ref={imageRef}
			/>
			{imageLoaded && (
				<div className={`featured-item-details ${imageLoaded ? 'image-loaded' : ''}`}>
					<h2 className="featured-item-title">{featuredItem.title}</h2>
					<ItemRating
						rating={featuredItem.rating}
						itemId={featuredItem.id}
						itemTitle={featuredItem.title}
						dispatch={dispatch}
						isAuthenticated // assume this component is only shown to authenticated users
						canRate
						onItemRated={handleOnItemRated}
					/>
					<div className="featured-item-description">
						{ReactAutolink.autolink(featuredItem.description, {
							rel: 'nofollow',
						})}
					</div>
					<div className="featured-item-controls">
						<Link
							to={
								featuredItem.promotion === 'PLUS' && !isPlusSubscriber
									? featuredItem.promotionPath || '/items/' + featuredItem.id
									: '/media/' + featuredItem.id
							}
							className="featured-item-play-button-wrapper"
						>
							<button className="featured-item-play-button">
								<div className="arrow-right" />
								{'watch'}
							</button>
						</Link>
						<div className="featured-item-mobile-button-divider" />
						<div className="featured-item-secondary-buttons">
							{trailer ? (
								<Link to={'/media/' + trailer.id}>
									<button className="featured-item-play-trailer-button">
										<div className="circle">
											<div className="arrow-right" />
										</div>
										{'watch trailer'}
									</button>
								</Link>
							) : (
								false
							)}
							<WatchlistButton itemId={featuredItem.id} dispatch={dispatch} watchlistItemIds={watchlistItemIds} />
						</div>
					</div>
				</div>
			)}
			<div className={`featured-item-changer ${imageLoaded ? 'image-loaded' : ''}`}>
				{featuredItems.map((item, itemIndex) => (
					<button
						onClick={() => handleChangeFeaturedItem(item)}
						className={item.id === featuredItem.id ? 'active' : ''}
						key={`${item.id}-${itemIndex}`}
					/>
				))}
			</div>
		</div>
	);
};
