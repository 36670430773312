import React from 'react';

export const RemoveIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
		<path
			fill="#62BB46"
			fillRule="evenodd"
			d="M21.6666667,11 C21.6666667,16.8912 16.8912,21.6666667 11,21.6666667 C5.1088,21.6666667 0.333333333,16.8912 0.333333333,11 C0.333333333,5.1088 5.1088,0.333333333 11,0.333333333 C16.8912,0.333333333 21.6666667,5.1088 21.6666667,11 Z M19.5333333,11 C19.5333333,6.28717013 15.7128299,2.46666667 11,2.46666667 C6.28717013,2.46666667 2.46666667,6.28717013 2.46666667,11 C2.46666667,15.7128299 6.28717013,19.5333333 11,19.5333333 C15.7128299,19.5333333 19.5333333,15.7128299 19.5333333,11 Z M5.66666667,9.8 L16.3333333,9.8 L16.3333333,12.2 L5.66666667,12.2 L5.66666667,9.8 Z"
		/>
	</svg>
);
