import React from 'react';
import Helmet from 'react-helmet';
import { connect } from '../../connect';
import { amplitudeLogEvent } from '../../helpers/amplitude-helper';
import { createOpenGraphMetadata } from '../../helpers/metadata-helper';
import { Categories } from './categories';
import { ContentManagement } from '../../components/content-management';
import { ErrorPage } from '../../components/error-page';
import { LoadingIndicator } from '../../components/loading-indicator';
import { SubscribeBanner } from '../../components/subscribe-banner';
import { FeaturedItemsCarousel } from '../../components/featured-items-carousel';
import { getContentManagementPageIfNecessary, getDefaultCategories, getFeaturedItems } from '../../actions';
import logger from '../../utils/logger';

import './index.less';
import { IAuthState } from '../../reducers/auth-reducers';
import { IContentManagementState } from '../../reducers/content-management-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { IPurchaseState } from '../../reducers/purchase-reducers';
import { ICategoriesState, IPlaylistsState } from '../../reducers/home-reducers';
import { IReduxProps } from '../../actions/action-types';
import { IFeatureFlagsState } from '../../reducers/feature-flags-reducers';
import { IWatchlistState } from '../../reducers/watchlist-reducers';
import { IFeaturedItemsState } from '../../reducers/featured-items-reducers';

function mapStateToProps({
	categories,
	playlists,
	auth,
	subscription,
	purchase,
	contentManagement,
	kidsMode,
	featureFlags,
	watchlist,
	featuredItems,
}) {
	return {
		categories,
		playlists,
		auth,
		subscription,
		purchase,
		contentManagement,
		kidsMode,
		featureFlags,
		watchlist,
		featuredItems,
	};
}

interface IHomePageProps extends IReduxProps {
	categories: ICategoriesState;
	playlists: IPlaylistsState;
	auth: IAuthState;
	location: URL;
	subscription: ISubscriptionState;
	purchase: IPurchaseState;
	contentManagement: IContentManagementState;
	kidsMode: IKidsModeState;
	featureFlags: IFeatureFlagsState;
	watchlist: IWatchlistState;
	featuredItems: IFeaturedItemsState;
}

@connect(mapStateToProps)
export default class HomePage extends React.Component<IHomePageProps> {
	static activate({ dispatch, getState }, routeData, hasActivated) {
		if (hasActivated) {
			return Promise.resolve();
		}
		const { auth } = getState();
		const userId = auth?.user?.id;
		const isAuthenticated = userId && userId !== '-1';
		return Promise.all([
			dispatch(isAuthenticated ? getFeaturedItems() : getContentManagementPageIfNecessary('/get-free-trial')).catch(
				logger.error
			),
			dispatch(getDefaultCategories()).catch(logger.error),
		]);
	}

	componentDidMount() {
		amplitudeLogEvent('Home - Viewed');
	}

	render() {
		const {
			subscription,
			purchase,
			categories: { categories, kidsCategories },
			playlists,
			auth,
			contentManagement,
			dispatch,
			kidsMode,
			watchlist: { itemIds: watchlistItemIds },
			featuredItems: { items: featuredItems },
		} = this.props;
		const user = auth.user;
		const isFetchingContentManagement = !contentManagement || contentManagement.isFetching;
		const meta = createOpenGraphMetadata();

		const headerScripts = this.props.auth.shouldExportSignIn
			? [{ src: '/auth/single-sign-on-cookies.js', type: 'text/javascript', defer: true }]
			: [];

		const categoriesList = kidsMode.isKidsMode ? kidsCategories : categories;
		let displayCategories = (!kidsMode.isKidsMode && categories.defaultCategories) || [];
		if (!categoriesList.isFetching && categoriesList.categories) {
			displayCategories = categoriesList.categories;
		}

		const isAuthenticated = !auth.isProcessing && auth.user && auth.user.id !== '-1';

		const isPlusSubscriber =
			subscription &&
			subscription.hasSubscription &&
			subscription.subscriptions &&
			subscription.subscriptions.includes('PLUS');

		return (
			<div className="home-page">
				<Helmet title="Christian Movies, TV Shows, Documentaries & more" script={headerScripts} meta={meta} />
				{user.id === '-1' ? (
					isFetchingContentManagement ? (
						<LoadingIndicator />
					) : (
						<ContentManagement
							dispatch={dispatch}
							pagePath="/get-free-trial"
							isFetching={isFetchingContentManagement}
							isError={contentManagement && contentManagement.isError}
							page={contentManagement && contentManagement.page}
						/>
					)
				) : subscription.hasSubscription === false ? (
					<SubscribeBanner />
				) : (
					false
				)}
				{featuredItems && featuredItems.length && !kidsMode.isKidsMode && isAuthenticated ? (
					<FeaturedItemsCarousel
						watchlistItemIds={watchlistItemIds}
						featuredItems={featuredItems}
						dispatch={dispatch}
						isPlusSubscriber={isPlusSubscriber}
					/>
				) : (
					false
				)}
				{categoriesList.isFetching && !displayCategories ? (
					isFetchingContentManagement ? (
						false
					) : (
						<LoadingIndicator />
					)
				) : displayCategories || playlists.playlists ? (
					<>
						<Categories
							categories={displayCategories || []}
							dispatch={dispatch}
							playlists={playlists.playlists || []}
							auth={auth}
							subscription={subscription}
							purchase={purchase}
							kidsMode={kidsMode}
						/>
						{categoriesList.isFetching ? <LoadingIndicator /> : false}
					</>
				) : categoriesList.error || playlists.error ? (
					<ErrorPage />
				) : (
					false
				)}
			</div>
		);
	}
}
