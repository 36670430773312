import React from 'react';
import remove from 'lodash.remove';
import { forceCheck } from 'react-lazyload';
import { Category } from './category';
import { Playlist, IPlaylist } from './playlist';
import { IAuthState } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { IPurchaseState } from '../../reducers/purchase-reducers';
import { IReduxProps } from '../../actions/action-types';
import { ICategory } from '../../reducers/category-reducers';

const prePlaylistCategoryIds: readonly string[] = ['watchlist', 'recent'];

interface ICategoriesProps extends IReduxProps {
	categories: ICategory[];
	playlists?: IPlaylist[];
	auth: IAuthState;
	subscription: ISubscriptionState;
	purchase: IPurchaseState;
	kidsMode: IKidsModeState;
}

export class Categories extends React.Component<ICategoriesProps> {
	componentDidUpdate(prevProps: ICategoriesProps) {
		const { categories, playlists } = this.props;
		const { categories: prevCategories, playlists: prevPlaylists } = prevProps;
		if (prevCategories !== categories || prevPlaylists !== playlists) {
			forceCheck();
		}
	}

	render() {
		const { categories, playlists, auth, subscription, purchase, dispatch, kidsMode } = this.props;
		const remainingCategories = [...categories];
		const prePlaylistCategories: ICategory[] = remove(remainingCategories, x => prePlaylistCategoryIds.includes(x.id));

		return (
			<div className="categories">
				{prePlaylistCategories
					? prePlaylistCategories.map(category => (
							<Category
								key={category.id}
								category={category}
								dispatch={dispatch}
								auth={auth}
								subscription={subscription}
								purchase={purchase}
								kidsMode={kidsMode}
							/>
					  ))
					: false}
				{playlists
					? playlists.map(playlist => (
							<Playlist key={playlist.id} playlist={playlist} dispatch={dispatch} subscription={subscription} />
					  ))
					: false}
				{remainingCategories
					? remainingCategories.map(category => (
							<Category
								key={category.id}
								category={category}
								dispatch={dispatch}
								auth={auth}
								subscription={subscription}
								purchase={purchase}
								kidsMode={kidsMode}
							/>
					  ))
					: false}
			</div>
		);
	}
}
