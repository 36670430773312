import React from 'react';
import Slider from '@faithlife/react-slick';
import { getCategoryCover } from '../../utils/category-utils';
import { getItemCover } from '../../utils/item-utils';
import { renderRibbonIfNecessary } from '../../utils/products';
import './index.less';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { ICategory, ICover } from '../../reducers/category-reducers';
import { IItem } from '../../reducers/item-reducers';
import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';

const defaultImageHeight = 180;
const coverPadding = 23;

interface IChevronProps {
	className?: string;
	style?: React.CSSProperties;
	onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
	directionRight?: boolean;
}

const Chevron = ({ className, style, onClick, directionRight }: IChevronProps) => (
	// TODO: Fix this the next time the file is edited.
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	<div className={className} style={style} onClick={onClick}>
		<img
			className="innerChevron"
			src={directionRight ? chevronRight : chevronLeft}
			alt={directionRight ? 'next' : 'previous'}
		/>
	</div>
);

interface IItemListProps {
	items: IItem[];
	categories?: ICategory[];
	imageHeight: number;
	renderItem: (item: IItem, cover: ICover | undefined, coverEl: JSX.Element, playlistId?: string) => JSX.Element;
	renderCategory?: (category: ICategory, cover: ICover | undefined, coverEl: JSX.Element) => JSX.Element;
	kidsMode?: IKidsModeState;
	getNextPage?: () => void;
	pageToken?: string | null;
	currSubItemIndex?: number;
	subItemWidth?: number;
	maxWidth?: number;
	playlistId?: string;
	subscription: ISubscriptionState;
	showPrice?: boolean;
	lazy?: boolean;
}

export class ItemList extends React.Component<IItemListProps> {
	private maxItemsShown: number = 0;
	private slider: Slider | undefined;

	_onAfterSlideChange = (nextSlideIndex: number) => {
		const { items, pageToken, playlistId, getNextPage } = this.props;
		if (getNextPage && nextSlideIndex >= items.length - this.maxItemsShown * 2 && (pageToken || playlistId)) {
			getNextPage();
		}
	};

	_onBeforeSlideChange = (previousSlideIndex: number, nextSlideIndex: number) => {
		this.maxItemsShown = Math.max(this.maxItemsShown, Math.abs(previousSlideIndex - nextSlideIndex));
	};

	componentWillReceiveProps(nextProps: IItemListProps) {
		const { currSubItemIndex: nextCurrSubItemIndex } = nextProps;
		if (this.props.currSubItemIndex !== nextCurrSubItemIndex && this.slider) {
			this.slider.slickGoTo(nextCurrSubItemIndex);
		}
	}

	render() {
		const {
			items,
			categories,
			subItemWidth,
			maxWidth,
			kidsMode,
			renderCategory,
			currSubItemIndex,
			renderItem,
			playlistId,
			lazy,
		} = this.props;
		const imageHeight = this.props.imageHeight || defaultImageHeight;
		const showPrice = typeof this.props.showPrice === 'undefined' || this.props.showPrice;
		// TODO: make Slider support automatic paging of slidesToScroll
		const settings = {
			dots: false,
			initialSlide: currSubItemIndex ? currSubItemIndex : 0,
			infinite: false,
			speed: 500,
			variableWidth: true,
			scrollVisibleSlides: true,
			afterChange: this._onAfterSlideChange,
			beforeChange: this._onBeforeSlideChange,
			prevArrow: <Chevron />,
			nextArrow: <Chevron directionRight />,
		};

		return (
			<Slider
				{...settings}
				className={`item-list ${kidsMode && kidsMode.isKidsMode ? 'kids' : ''}`}
				ref={ref => (this.slider = ref)}
			>
				{categories && renderCategory
					? categories.map((category, catIndex) => {
							const cover = getCategoryCover({
								category,
								height: imageHeight,
								maxWidth,
							});
							const coverEl = cover ? (
								<img
									src={cover.url}
									style={{ height: cover.height, width: cover.width }}
									alt={category.title}
									loading={lazy ? 'lazy' : undefined}
								/>
							) : (
								<img style={{ height: imageHeight, width: imageHeight }} alt={category.title} />
							);
							return (
								<div
									key={`${category.id}-${catIndex}`}
									style={subItemWidth ? { width: subItemWidth + coverPadding } : undefined}
								>
									{renderCategory(category, cover, coverEl)}
								</div>
							);
					  })
					: null}
				{items.map((item, itemIndex) => {
					const { licenseKind, productInfo } = item;
					const cover = getItemCover({ item, height: imageHeight, maxWidth });
					const coverEl = cover ? (
						<img
							src={cover.url}
							style={{ height: cover.height, width: cover.width }}
							alt={item.title}
							loading={lazy ? 'lazy' : undefined}
						/>
					) : (
						<img style={{ height: imageHeight, width: imageHeight }} alt={item.title} />
					);
					const priceDisplayAmount =
						showPrice && productInfo && productInfo.addToCart ? productInfo.addToCart.displayAmount : null;
					const subscriptionPeriodKind =
						productInfo && productInfo.addToCart && productInfo.addToCart.subscriptionPeriodKind;

					return (
						<div
							key={`${item.id}-${itemIndex}`}
							style={subItemWidth ? { width: subItemWidth + coverPadding } : undefined}
						>
							{renderItem(item, cover, coverEl, playlistId)}
							{renderRibbonIfNecessary(
								{
									priceDisplayAmount,
									showFreeCornerRibbon: showPrice && licenseKind === 'free',
									showPromotionRibbon: licenseKind === 'plus',
									showOwnedCornerRibbon: licenseKind === 'owned',
									subscriptionPeriodKind,
								},
								'item'
							)}
						</div>
					);
				})}
			</Slider>
		);
	}
}
