import React from 'react';
import { Link } from 'react-router-dom';
import './index.less';
import PlayButtonImage60 from './play-button-60w.png';
import PlayButtonImage120 from './play-button-120w.png';

const PlayButtonImage = () => (
	<img
		src={PlayButtonImage60}
		srcSet={`${PlayButtonImage60} 60w, ${PlayButtonImage120} 120w`}
		width={60}
		height={60}
		alt="Play"
	/>
);

interface IPlayButtonProps {
	itemId?: string;
	playlistId?: string;
	externalPlayUrl?: string;
}

export class PlayButton extends React.Component<IPlayButtonProps> {
	render() {
		const { itemId, playlistId, externalPlayUrl, ...rest } = this.props;

		const playLink = playlistId ? `/media/${itemId}?playlistId=${playlistId}&resume=false` : `/media/${itemId}`;

		return (
			<div className="play-button" {...rest}>
				{externalPlayUrl ? (
					<a href={externalPlayUrl}>
						<PlayButtonImage />
					</a>
				) : (
					<Link to={playLink}>
						<PlayButtonImage />
					</Link>
				)}
			</div>
		);
	}
}
