import React, { useState, useEffect } from 'react';
import { RemoveIcon } from './RemoveIcon';
import { AddIcon } from './AddIcon';
import { removeItemFromWatchlist, addItemToWatchlist, updateCategories } from '../../actions';
import logger from '../../utils/logger';
import './index.less';
import { IActionDispatch } from '../../actions/action-types';

interface IWatchlistButtonProps {
	itemId: string;
	watchlistItemIds?: readonly string[] | null;
	dispatch: IActionDispatch;
}

export const WatchlistButton: React.FC<IWatchlistButtonProps> = ({ itemId, watchlistItemIds, dispatch }) => {
	const [isTogglingWatchlistStatus, setIsTogglingWatchlistStatus] = useState<boolean>(false);
	const [isItemInWatchlist, setIsItemInWatchlist] = useState<boolean | null>(null);

	useEffect(() => {
		if (watchlistItemIds) {
			setIsTogglingWatchlistStatus(false);
			setIsItemInWatchlist(watchlistItemIds.includes(itemId));
		}
	}, [isItemInWatchlist, watchlistItemIds, itemId]);

	const handleToggleWatchlistStatus = () => {
		setIsTogglingWatchlistStatus(true);
		dispatch(isItemInWatchlist ? removeItemFromWatchlist(itemId) : addItemToWatchlist(itemId))
			.then(response => {
				if (response) {
					dispatch(updateCategories()).catch(logger.error);
				}
			})
			.catch(logger.error);
	};

	return isItemInWatchlist !== null ? (
		<button className="watchlist-button" onClick={handleToggleWatchlistStatus}>
			{isItemInWatchlist ? <RemoveIcon /> : <AddIcon />}
			<span>
				{isTogglingWatchlistStatus
					? isItemInWatchlist
						? 'Removing…'
						: 'Adding…'
					: `${isItemInWatchlist ? 'Remove from' : 'Add to'} watchlist`}
			</span>
		</button>
	) : null;
};
