import React from 'react';
import { getPurchaseButtonLabel } from '../../utils/products';
import './index.less';
const initialState = 'initial';
const confirmState = 'confirm';
const purchasingState = 'purchasing';
const successState = 'success';
const errorState = 'error';

export const purchaseButtonType = {
	Normal: 'normal',
	Compact: 'compact',
};

interface IPurchaseButtonProps {
	purchase: {
		price: string;
		subscriptionPeriodKind: string;
	};
	onPurchase: () => void;
	isSuccess?: boolean;
	error?: string;
	type?: string;
}

interface IPurchaseButtonState {
	purchaseState: string;
}

export class PurchaseButton extends React.Component<IPurchaseButtonProps, IPurchaseButtonState> {
	state: IPurchaseButtonState = {
		purchaseState: initialState,
	};

	componentWillMount() {
		const { isSuccess, error } = this.props;

		if (error) {
			this.setState({ purchaseState: errorState });
		} else if (isSuccess) {
			this.setState({ purchaseState: successState });
		}
	}

	componentWillReceiveProps(nextProps: IPurchaseButtonProps) {
		const { isSuccess, error } = this.props;
		const { isSuccess: nextIsSuccess, error: nextError } = nextProps;

		if (nextIsSuccess !== isSuccess || !!nextError !== !!error) {
			if (nextIsSuccess) {
				this.setState({ purchaseState: successState });
			} else if (nextError) {
				this.setState({ purchaseState: errorState });
			}
		}
	}

	_handleButtonClick() {
		const { purchaseState } = this.state;

		if (purchaseState === initialState) {
			this.setState({ purchaseState: confirmState });
		} else if (purchaseState === confirmState) {
			this.setState({ purchaseState: purchasingState });
			this.props.onPurchase();
		}
	}

	_handleCancelClick() {
		this.setState({ purchaseState: initialState });
	}

	render() {
		const { purchaseState } = this.state;
		const { purchase, type, error } = this.props;
		let buttonText;
		let buttonClass = 'purchase-button';
		const isSubscription = !!(purchase && purchase.subscriptionPeriodKind);

		if (purchaseState === initialState) {
			if (type === purchaseButtonType.Compact) {
				buttonText = 'Subscribe';
			} else {
				buttonText = getPurchaseButtonLabel(purchase);
			}
		} else if (purchaseState === confirmState) {
			if (isSubscription) {
				if (type === purchaseButtonType.Compact) {
					buttonText = 'Confirm';
				} else {
					buttonText = 'Confirm Subscription';
				}
			} else {
				buttonText = 'Confirm Purchase';
			}
			buttonClass = `${buttonClass} purchase-button-confirm`;
		} else if (purchaseState === purchasingState) {
			if (isSubscription) {
				buttonText = 'Subscribing...';
			} else {
				buttonText = 'Purchasing...';
			}
			buttonClass = `${buttonClass} purchase-button-purchasing`;
		} else if (purchaseState === successState) {
			if (isSubscription) {
				buttonText = 'Subscribed';
			} else {
				buttonText = 'Purchased';
			}
		} else if (purchaseState === errorState) {
			buttonText = 'Error';
			buttonClass = `${buttonClass} purchase-button-error`;
		}

		return (
			<div className={`purchase-button ${type || ''}`}>
				<div>
					<button className={buttonClass} onClick={() => this._handleButtonClick()}>
						{buttonText}
					</button>
				</div>
				{purchaseState === confirmState ? (
					<div className="purchase-cancel">
						{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
						<span onClick={() => this._handleCancelClick()}>Cancel</span>
					</div>
				) : null}
				{purchaseState === errorState ? <div className="purchase-error-text">{error}</div> : false}
			</div>
		);
	}
}
