import React from 'react';
import { lazyload } from 'react-lazyload';
import { Link } from 'react-router-dom';
import { ItemList } from '../../components/item-list';
import { PlayButton } from '../../components/play-button';
import { getItemsInPlaylist } from '../../actions';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IReduxProps } from '../../actions/action-types';
import { IItem } from '../../reducers/item-reducers';
import { ICover } from '../../reducers/category-reducers';

const imageHeight = 180;
const layerSize = 10;

export interface IPlaylist {
	id: string;
	title: string;
	items: IItem[];
}

interface IPlaylistProps extends IReduxProps {
	playlist: IPlaylist;
	subscription: ISubscriptionState;
}

interface IPlaylistState {
	items: IItem[];
}

@lazyload({
	height: imageHeight + 19,
	once: true,
	offset: 100,
	resize: true,
})
export class Playlist extends React.Component<IPlaylistProps, IPlaylistState> {
	state: IPlaylistState = {
		items: [],
	};

	componentDidMount() {
		const playlist = this.props.playlist;
		const items = playlist && playlist.items && playlist.items.length ? playlist.items.slice() : [];
		const playAllTitle = 'Play All';

		if (items.length && items[0].title !== playAllTitle && items[0].covers && items[0].covers.length) {
			const playlistCover = items[0].covers[0];
			items.unshift({
				id: 'Play_all',
				itemId: items[0].id,
				title: playAllTitle,
				covers: [
					{
						height: playlistCover.height,
						width: playlistCover.width && playlistCover.width + layerSize,
						url: playlistCover.url,
					},
				],
				isPlaylistCover: true,
			});
		}
		this.setState({ items }); // eslint-disable-line react/no-did-mount-set-state
	}

	_renderItem(item: IItem, cover: ICover | undefined, coverEl: JSX.Element, playlistId?: string): JSX.Element {
		if (item.isPlaylistCover) {
			const layerSize = 10;
			return (
				<div className="playlist playlist-cover">
					<div
						style={{
							position: 'absolute',
							width: cover && cover.width,
							top: '50px',
							textAlign: 'center',
						}}
					>
						{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/alt-text */}
						<img className="playlist-cover-button" src="public/images/play-button-green.png" />
						<h3 className="playlist-cover-text">Play All</h3>
					</div>
					<Link to={`/media/${item.itemId}?playlistId=${playlistId}&resume=false`} title={item.title}>
						<div className="playlist-cover-image">
							<img
								src={cover && cover.url}
								style={{ height: cover && cover.height, width: cover && cover.width && cover.width - layerSize }}
								alt={item.title}
							/>
						</div>
						<div
							className="layer-1"
							style={{
								display: 'inline-block',
								background: '#414141',
								width: '7px',
								height: cover && cover.height && cover.height - layerSize,
								marginBottom: `${layerSize / 2}px`,
							}}
						/>
						<div
							className="layer-2"
							style={{
								display: 'inline-block',
								background: '#646464',
								width: '7px',
								height: cover && cover.height && cover.height - layerSize * 2,
								marginBottom: `${layerSize}px`,
							}}
						/>
					</Link>
				</div>
			);
		}

		return (
			<div className="item">
				<Link to={`/media/${item.id}?playlistId=${playlistId}&resume=false`} title={item.title}>
					{coverEl}
				</Link>
				<PlayButton itemId={item.id} playlistId={playlistId} />
			</div>
		);
	}

	_getNextPage() {
		const {
			playlist: { id, items },
			dispatch,
		} = this.props;
		const lastItemId = items[items.length - 1].id;
		dispatch(getItemsInPlaylist(lastItemId, id));
	}

	render() {
		const { playlist, subscription } = this.props;
		const playlistId = playlist.id;
		const items = this.state.items;

		if (!items.length) {
			return false;
		}

		return (
			<section className="slick-container">
				<h3 className="title">{playlist.title}</h3>
				<ItemList
					items={items}
					playlistId={playlistId}
					imageHeight={imageHeight}
					renderItem={(item, cover, coverEl, playlistId) => this._renderItem(item, cover, coverEl, playlistId)}
					getNextPage={() => this._getNextPage()}
					subscription={subscription}
				/>
			</section>
		);
	}
}
